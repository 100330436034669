@use '@angular/material' as mat;
@import '@angular/material/theming';
// Plus imports for other components in your app.

/*    ----------------typography----------------------------- */
// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.
$my-typography: mat.define-typography-config(
  $font-family: "'AvenirNext', sans-serif",
  $headline: mat.define-typography-level(32px, 48px, 700),
  $title: mat.define-typography-level(24px, 38px, 600),
  $body-1: mat.define-typography-level(16px, 24px, 500)
);

body{
  margin: 0;
  color: #fff;
  font-family: 'AvenirNext', sans-serif;
  background-color: #5683b7;
}

$mat-blue-navy: (
  50: #e3e4ed,
  100: #b8bbd2,
  200:#8b8fb4,
  300: #606596,
  400: #424783,
  500: #232b70,
  600: #1e2568,
  700: #161d5e,
  800: #0d1452,
  900: #5683b7, 
  A100: #e3e4ed,
  A200: #b8bbd2,
  A400: #8b8fb4,
  A700:#606596,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $light-primary-text,
  )
);

$mat-error: (
  50: #f3b458,
  100: #f3b458,
  200:#f3b458,
  300: #f3b458,
  400: #f3b458,
  500: #f3b458,
  600: #f3b458,
  700: #f3b458,
  800: #f3b458,
  900: #f3b458, 
  A100: #f3b458,
  A200: #f3b458,
  A400: #f3b458,
  A700:#f3b458,
  contrast: (
    50: $light-primary-text,
    100: $light-primary-text,
    200: $light-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $light-primary-text,
  )
 
  

 

);

$mat-brown-light: (
  50: #e3f3ff,
  100:#bbdfff,
  200:#8ecdff,
  300:#5bb8ff,
  400:#2fa8ff,
  500:#0098ff,
  600: #0b8aff,
  700: #1876f2,
  800: #1d64df,
  900: #2243c0,
  A100:#e3f3ff,
  A200:#bbdfff,
  A400: #8ecdff,
  A700: #5bb8ff,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $light-primary-text,
  )
);


// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core($my-typography);



// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$my-theme-primary: mat.define-palette($mat-blue-navy, 900, A100, 900);//$base-palette, $default: 500, $lighter: 100, $darker: 700
$my-theme-accent:  mat.define-palette($mat-brown-light, 700, A100, 900 ); //secondary

// $my-theme-warn: mat-palette($mat-brown-light, 100, 300, 200 ); //secondary



// The warn palette is optional (defaults to red).
$my-theme-warn:  mat.define-palette($mat-error, 50);

// Create the theme object (a Sass map containing all of the palettes).
$my-theme-theme: mat.define-light-theme($my-theme-primary, $my-theme-accent, $my-theme-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($my-theme-theme);






