
// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
@import '@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$WebSite-primary: mat.define-palette(mat.$indigo-palette);
$WebSite-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$WebSite-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$WebSite-theme: mat.define-light-theme((
  color: (
    primary: $WebSite-primary,
    accent: $WebSite-accent,
    warn: $WebSite-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($WebSite-theme);

@import './my-theme';

hr.rainbow{
  height: 2px;
  margin: 0px;
  background: #e8a500;
  background: -webkit-gradient(linear, left top, right bottom, color-stop(0%,#e8a500), color-stop(25%,#db0026), color-stop(50%,#dd1385), color-stop(75%,#008c76), color-stop(100%,#007aba));
  background: -webkit-linear-gradient(-45deg, #e8a500 0%,#db0026 25%,#dd1385 50%,#008c76 75%,#007aba 100%);
  background: -moz-linear-gradient(-45deg, #e8a500 0%, #db0026 25%, #dd1385 50%, #008c76 75%, #007aba 100%);
  background: -o-linear-gradient(-45deg, #e8a500 0%,#db0026 25%,#dd1385 50%,#008c76 75%,#007aba 100%);
  background: -ms-linear-gradient(-45deg, #e8a500 0%,#db0026 25%,#dd1385 50%,#008c76 75%,#007aba 100%);
  background: linear-gradient(-45deg, #e8a500 0%,#db0026 25%,#dd1385 50%,#008c76 75%,#007aba 100%);
}

.body {
  background-color: transparent;
  background-image: url('../assets/images/amicipremiati-pattern.png');
  background-position: 0px 0px;
  background-size: 40%;
  background-repeat: repeat;
}

.body, .mat-toolbar, .mat-card  {
 /*  color: mat-color($my-theme-primary, 900);*/


}

.herobanner{
  cursor:pointer;
}

.logoacel{
  max-width: 30%;
}
.cdk-overlay-pane.my-dialog {
  position: relative!important;
}
.close.mat-button,.close {
  position: relative;
  top: 0;
  right: 0;
  padding: 5px !important;
  font-size: 15px !important;
  line-height: 14px;
  min-width: auto;
  margin-right:10px;
}

a, a:hover {
  color: mat.get-color-from-palette($my-theme-primary, 900);
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  opacity: 0.6;
}

.logo, .card {
  width: 80%;
  margin: auto;
  
}

.card-big {
  width: 70%;
  margin: auto;
  
}

.card, .card-big {
  /* margin-top: 50px; */
}

.logo{
  padding-top: 20px;
}

/* Margin */

.mt-20 {
  margin-top: 20px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-30 {
  margin-top: 30px;
}
.mt-50 {
  margin-top: 50px;
}
.mt-60 {
  margin-top: 60px;
}
.ml-10{
  margin-left: 10px;
}

.ml-20{
  margin-left: 20px;
}
.ml-30{
  margin-left: 30px;
}

.ml-80 {
  margin-left: 80px;
}

.mr-30 {
  margin-right: 30px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.text-center { 
  text-align: center;
}

/* font */
h1 {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 30px;
}
h3, h2 {
  font-weight: bold;
}

p {
  font-size: 16px;
}



.bold {
  font-weight: bold;
}

.energy-button {
  width: auto;
  /* padding: 0 40px; */
  margin: auto;
  border-radius: 0px;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 1px;
}  


.text-white, .text-white:hover {
  color: #ffffff;
}

.text-top-link, .text-top-link:hover {
  font-size: 12px;
  text-decoration: underline;
  line-height: 44px;
}

.text-top-link:hover {
  opacity: 0.6;
 
}

.heading-title {
  font-weight: normal;
}

.myapp-no-padding-dialog .mat-dialog-container,
.myapp-no-padding-dialog-login-fb .mat-dialog-container {
  padding: 20px 0px;
  border-radius: 0px;
  text-align: center;
}

.myapp-no-padding-dialog-login-fb {
  overflow: scroll;
}


.myapp-no-padding-dialog-login-fb section#modal-body h3{
  font-size: 12px;
  line-height: 20px;
}

#modal-body .text-block.text-blu.text-center {
  font-size: 18px;
  font-weight: 500;
}
#modal-footer {
  margin: 20px 0px;
}
#modal-footer a {
  background-color: #5683b7; 
    color: white;
    padding: 10px;
    text-decoration: none !important;
    text-transform: uppercase;
    font-weight: bold;
    border: 3px solid #5683b7; 
}
.mat-raised-button.mat-primary {
  text-transform: uppercase !important;
  border: 3px solid #f3b458; 
}
.mat-button-disabled,
#modal-footer a.cancel-button{
  background-color: transparent !important;
    border: 2px solid #f3b458 !important;
    color: #f3b458 !important;
    text-transform: uppercase !important;
    cursor: pointer;
    font-family: 'AvenirNext-Bold';
}

.mat-button-disabled:hover {
 opacity: 0.8 !important;
 cursor: pointer;
}

/* footer */
footer .footer-copyright {
  background-color: mat.get-color-from-palette($my-theme-primary, 900);
  color: $light-primary-text;
  padding-top: 50px;
}

.footer-copyright,
.footer-copyright a,
.footer-copyright span {
  font-size: 10px;
}

.footer-sponsor a:hover {
  text-transform: none;
  color: mat.get-color-from-palette($my-theme-primary, 900);
  opacity: 0.6;
}

.text-smaller {
  font-size: 12px;
}

mat-nav-list.list-horizontal {
  display: inline-block;
  flex-direction: row;
}

.team-images img {
  height: 45px;
  margin: 4px;
}

.powered {
  width: 100%;
  margin-top: 30px;
  display: block;
}

.facebook {
  width: 60%;
  margin: 20px 0;
  display: block;
}

@media (max-width: 998px) { 
  .card, .card-big {
    width: 98%;
  }
 
  .team-images img {
    height: 38px;
    margin: 4px;
  }
 
}

@media (max-width: 768px) { 


  .team-images img {
    height: 60px;
    margin: 4px;
  }
  .logoacel{
    max-width: 70%;
  }
}


